import { usePaymentSubscriptionInfo } from "~/endpoints/payment";
import useCurrentViewerVaspDID from "~/hooks/useCurrentViewerVaspDID";

type SubscriptionInfoParams = {
  includePortalSession?: boolean;
};

export default function useCurrentViewerSubscriptionInfo({
  includePortalSession = false,
}: SubscriptionInfoParams = {}) {
  const vaspDID = useCurrentViewerVaspDID();
  const { data: paymentSubscriptionInfo, isLoading, error } = usePaymentSubscriptionInfo(
    vaspDID ? { vaspDID, includePortalSession } : null,
  );

  return {
    isLoading,
    paymentSubscriptionInfo,
    error,
  };
}
