import Loader from "react-loader-spinner";
import styled from "styled-components";

export const AbsoluteCenter = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100%;
`;

export default function Loading({ isOnline }: { isOnline?: boolean | undefined }) {
  return (
    <AbsoluteCenter data-testid="loading-spinner">
      <Loader type="MutatingDots" color="#48E231" height={100} width={100} />
      {
        isOnline === false && <>No server connection</> // explicit boolean check
      }
    </AbsoluteCenter>
  );
}
