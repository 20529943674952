/* eslint-disable @typescript-eslint/no-empty-function */

import { createContext, useEffect } from "react";
import { useState } from "react";

import getConfig from "next/config";
import { Svix } from "svix";

import useCurrentViewerVaspDID from "~/hooks/useCurrentViewerVaspDID";
import { getConsumerApplicationUID } from "~/templates/webhooks/utils";

type WebhookContextType = {
  url: string;
  deleteConsumerApp: () => void;
  createConsumerApp: () => void;
};

export const INITIAL_VALUE: WebhookContextType = {
  url: "",
  createConsumerApp: () => {},
  deleteConsumerApp: () => {},
};

export const WebhookContext = createContext<WebhookContextType>(INITIAL_VALUE);

export function WebhookContextProvider(props: { children: React.ReactNode }) {
  const [webhookUrl, setWebhookUrl] = useState("");

  const { publicRuntimeConfig } = getConfig();
  const svix = new Svix(publicRuntimeConfig.svix.token);
  const vaspDID = useCurrentViewerVaspDID();
  const appUID = getConsumerApplicationUID(vaspDID);

  useEffect(() => {
    svix.authentication
      .appPortalAccess(appUID, {})
      .then((response) => setWebhookUrl(response.url))
      .catch((err) => setWebhookUrl(""));
  }, []);

  const deleteConsumerApp = async () => {
    await svix.application.delete(appUID);
    setWebhookUrl("");
  };

  const createConsumerApp = async () => {
    await svix.application.getOrCreate({ uid: appUID, name: vaspDID });
    const { url } = await svix.authentication.appPortalAccess(appUID, {});
    setWebhookUrl(url);
  };

  const viewerContextValue: WebhookContextType = {
    url: webhookUrl,
    createConsumerApp,
    deleteConsumerApp,
  };

  return <WebhookContext.Provider value={viewerContextValue}>{props.children}</WebhookContext.Provider>;
}
