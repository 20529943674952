import { useEffect } from "react";
import React from "react";

import * as snippet from "@segment/snippet";
import router from "next/router";
import Script from "next/script";
import styled from "styled-components";

import { trackUserAuthenticated } from "./analytics";

import { initUserConsent, updateUserConsent } from ".";
import { Button } from "~/components/Button";
import { InfoIcon } from "~/components/icons/Info";
import { Container as Notification } from "~/components/Notification";
import { SubscriptionStatus, SubscriptionType } from "~/endpoints/payment/types";
import { useTfSimpleVaspInfo } from "~/endpoints/trustFramework";
import { useSegmentWriteKey } from "~/endpoints/utils";
import useCurrentViewer from "~/hooks/useCurrentViewer";
import useCurrentViewerSubscriptionInfo from "~/hooks/useCurrentViewerSubscriptionInfo";
import useCurrentViewerVaspDID from "~/hooks/useCurrentViewerVaspDID";

export const globalAny: any = global; // Make ts happy with global object

const NotificationBody = styled.main`
  margin: 0 auto;
  width: 90%;
  max-width: 1500px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  background-color: #e9e9e9;
`;

const CookieNotification = styled(Notification)`
  position: fixed;
  margin: 0 !important;
  background-color: #e9e9e9;
  z-index: 100;
  display: flex;
  bottom: 0;
  width: 100%;
`;

const NotificationText = styled.div`
  font-size: 16px;
  width: 100%;
  margin: 0;
`;

const NotificationTextContainer = styled.div`
  margin: 20px 0;
  max-width: 800px;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const CookieTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

export default function AnalyticsScriptLoader() {
  const { viewer: authViewerData } = useCurrentViewer();

  const { isLoading: isLoadingSubscriptionInfo, paymentSubscriptionInfo } = useCurrentViewerSubscriptionInfo();
  const [userAcceptedCookies, setUserAcceptedCookies] = React.useState<null | boolean>(null);
  const vaspDID = useCurrentViewerVaspDID();
  const { data: vaspInformation } = useTfSimpleVaspInfo(vaspDID);

  const [analyticsReady, setAnalyticsReady] = React.useState<null | boolean>(null);
  useEffect(() => {
    if (globalAny?.analytics !== undefined) {
      globalAny.analytics.ready(function () {
        setAnalyticsReady(true);
      });
    }
  }, []);

  const SEGMENT_WRITE_KEY = useSegmentWriteKey();

  useEffect(() => {
    const consent = JSON.parse(localStorage.getItem("analytics-user-consent") as string);
    if (consent !== null) {
      setUserAcceptedCookies(consent);
      initUserConsent(consent);
    }
  }, []);

  useEffect(() => {
    if (
      authViewerData?.did &&
      userAcceptedCookies &&
      !isLoadingSubscriptionInfo &&
      vaspInformation &&
      analyticsReady // Check segment ready
    ) {
      const trackedSubscriptionInfo = paymentSubscriptionInfo ?? {
        did: authViewerData?.did,
        customerId: "unknown",
        subscriptionId: "unknown",
        currentBillingPeriodUsage: -1,
        subscriptionType: SubscriptionType.PAYING,
        subscriptionStatus: SubscriptionStatus.ACTIVE,
      };
      trackUserAuthenticated(authViewerData, trackedSubscriptionInfo, vaspInformation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authViewerData?.did, analyticsReady, userAcceptedCookies, isLoadingSubscriptionInfo, vaspInformation]);

  useEffect(() => {
    if (userAcceptedCookies) {
      const trackPageChange = () => {
        try {
          if (globalAny.analytics) globalAny.analytics.page();
        } catch (error) {
          // avoid showing errors for analytics
        }
      };
      router.events.on("routeChangeComplete", trackPageChange);
      return () => {
        router.events.off("routeChangeComplete", trackPageChange);
      };
    }
    return;
  }, [userAcceptedCookies]);

  const renderAnalyticsSnippet = () => {
    if (SEGMENT_WRITE_KEY && globalAny.analytics === undefined) {
      const opts = {
        host: "cdn.segment.com",
        apiKey: SEGMENT_WRITE_KEY,
        page: false,
      };
      return snippet.min(opts);
    } else {
      return "";
    }
  };

  const onChangeConsent = (consent: boolean) => {
    setUserAcceptedCookies(consent);
    localStorage.setItem("analytics-user-consent", String(consent));
    updateUserConsent(consent);
  };

  return (
    <>
      <Script dangerouslySetInnerHTML={{ __html: renderAnalyticsSnippet() }} />
      {userAcceptedCookies === null && (
        <CookieNotification>
          <NotificationBody>
            <CookieTextContainer>
              <div style={{ margin: "0 20px" }}>
                <InfoIcon />
              </div>
              <NotificationTextContainer>
                <NotificationText>
                  This website uses necessary cookies to function. If you give your consent, we will also use statistic
                  cookies to allow us to collect data for aggregated statistics to improve our services.
                </NotificationText>
                <NotificationText>
                  Our{" "}
                  <a href="https://notabene.id/privacy-policy" target="_blank" rel="noreferrer">
                    privacy policy
                  </a>{" "}
                  provides more information on how we use cookies.
                </NotificationText>
              </NotificationTextContainer>
            </CookieTextContainer>

            <div>
              <Button
                style={{ margin: 10 }}
                kind="success"
                onClick={() => {
                  onChangeConsent(true);
                }}
              >
                Accept Statistics Cookies
              </Button>
              <Button
                style={{ margin: 10 }}
                kind="success"
                onClick={() => {
                  onChangeConsent(false);
                }}
              >
                Essential Cookies Only
              </Button>
            </div>
          </NotificationBody>
        </CookieNotification>
      )}
    </>
  );
}
