import { useEffect } from "react";

import { useIntercom } from "react-use-intercom";

import useSession from "~/hooks/use-session";

type IntercomChatProps = {
  children: React.ReactNode;
};

export default function IntercomChat(props: IntercomChatProps) {
  const { update } = useIntercom();

  const { data: sessionData } = useSession();

  useEffect(() => {
    if (sessionData?.viewer?.fields) {
      const viewer = sessionData.viewer;
      const emailFieldName = viewer.fields.find((field) => field.fieldName === "email");
      if (emailFieldName && emailFieldName.values.length > 0) {
        const viewerMail = emailFieldName.values[0].value;
        update({
          email: viewerMail,
        });
      }
    }
  }, [sessionData?.viewer, sessionData?.viewer?.fields, update]);

  return <>{props.children}</>;
}
