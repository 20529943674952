/* eslint-disable no-shadow */
import { datadogRum } from "@datadog/browser-rum";
import getConfig from "next/config";

const { publicRuntimeConfig: config } = getConfig();

export type UserSessionInit = {
  userDid: string;
};

export enum UserSessionPropertyOptions {
  //we can add as many as we see fit
  VASP_DID = "VaspDid",
  ROLE = "Role",
}

export const IGNORED_ROUTES_FOR_DD_RUM_INIT = ["/auth", "/auth/get-started"];

const initDatadogRum = () => {
  const isEnabled = !!config.rum.token;

  if (isEnabled) {
    datadogRum.init({
      applicationId: config.rum.client,
      clientToken: config.rum.token,
      site: "datadoghq.eu",
      service: config.rum.service,
      env: config.rum.env,
      version: config.rum.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: config.rum.sessionReplayEnabled ? 100 : 0,
      trackUserInteractions: config.rum.sessionReplayEnabled,
      trackFrustrations: config.rum.sessionReplayEnabled,
      defaultPrivacyLevel: config.rum.privacyLevel,
      allowedTracingUrls: config.rum.allowedTracingUrls,
      trackResources: true,
      trackLongTasks: true,
      enableExperimentalFeatures: ["feature_flags", ...(config.rum.sessionReplayEnabled ? ["clickmap"] : [])],
    });

    if (config.rum.sessionReplayEnabled) {
      datadogRum.startSessionReplayRecording();
    }
  }
};

const initDatadogRumUserSession = ({ userDid }: UserSessionInit) => {
  const isEnabled = config.rum && !!config.rum.token;
  if (isEnabled) {
    datadogRum.setUser({
      id: userDid,
    });
  }
};

const addDatadogRumUserSessionValue = (propertyName: UserSessionPropertyOptions, propertyValue: string) => {
  const isEnabled = config.rum && !!config.rum.token;
  if (isEnabled) {
    datadogRum.setUserProperty(propertyName, propertyValue);
  }
};

export { datadogRum, initDatadogRum, initDatadogRumUserSession, addDatadogRumUserSessionValue };
