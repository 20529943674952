import { createContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { useTfUserVaspList } from "~/endpoints/trustFramework";
import { UserVASPListInput, UserVASPListResponse } from "~/endpoints/trustFramework/types";
import useCurrentViewer from "~/hooks/useCurrentViewer";
import { VASP } from "~/types/vaspid";
import { UserSessionPropertyOptions, addDatadogRumUserSessionValue } from "~/utils/dd-rum";

type VaspContextType = {
  activeVaspDid: string;
  getActiveVasp: () => VASP | undefined;
  vasps: UserVASPListResponse;
  setActiveVASP: (did: string) => void;
  clearActiveVasp: () => void;
  refresh: () => void;
  mutate?: any;
  isLoading: boolean;
};

export const VASP_CONTEXT_INITIAL_DEFAULT_VALUE: VaspContextType = {
  activeVaspDid: "",
  getActiveVasp: () => {
    return undefined;
  },
  vasps: { joinable: [], claimable: [], loginable: [] },
  setActiveVASP: () => {
    /**just init, do nothing */
  },
  refresh: () => {
    /* just init, do nothing*/
  },
  isLoading: false,
  clearActiveVasp: () => {
    /* just init, do nothing*/
  },
};

export const VaspContext = createContext<VaspContextType>(VASP_CONTEXT_INITIAL_DEFAULT_VALUE);

const getInitialState = () => {
  if (typeof window === "undefined") {
    return VASP_CONTEXT_INITIAL_DEFAULT_VALUE.activeVaspDid;
  }
  let storedActiveVasp = localStorage.getItem("activeVasp");
  if (storedActiveVasp && !storedActiveVasp.startsWith("did:")) storedActiveVasp = null;
  return storedActiveVasp || VASP_CONTEXT_INITIAL_DEFAULT_VALUE.activeVaspDid;
};

export function VaspContextProvider(props: { children: React.ReactNode }) {
  const { viewer: currentViewer, mutate: refreshViewer } = useCurrentViewer();
  const [activeVaspDid, setActiveVASPDid] = useState<VaspContextType["activeVaspDid"]>(getInitialState);
  const router = useRouter();

  const hasCurrentViewer = currentViewer.did !== "noUser";

  const { setCompanyWizardState } = currentViewer;

  const variables: UserVASPListInput = {
    fields: [
      "did,name,website,logo,jurisdictions,division,hasAdmin,adminEmails,emailDomains,subsidiaries,isNotifiable,verificationStatus,subscription_id,untracked_usage_in_period,billing_customer_did,compliancePhase,compliancePhaseData,subsidiaryOf",
    ],
  };
  const { data, isLoading, mutate } = useTfUserVaspList<UserVASPListResponse>(hasCurrentViewer ? variables : null);

  useEffect(() => {
    // Solution for multiple tabs/windows and MultipleVasps
    const interval = setInterval(() => {
      const storedActiveVaspDid = localStorage.getItem("activeVasp");
      if (storedActiveVaspDid && storedActiveVaspDid.startsWith("did:") && storedActiveVaspDid !== activeVaspDid) {
        setActiveVASPDid(storedActiveVaspDid);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [activeVaspDid]);

  useEffect(() => {
    //retrieve active vasp from local storage at startup, if any
    if (typeof window === "undefined") {
      return;
    }
    const storedActiveVaspDid = localStorage.getItem("activeVasp");
    if (storedActiveVaspDid && storedActiveVaspDid.startsWith("did:")) {
      setActiveVASPDid(storedActiveVaspDid);
    }
  }, []);

  useEffect(() => {
    if (!currentViewer || !activeVaspDid || activeVaspDid === VASP_CONTEXT_INITIAL_DEFAULT_VALUE.activeVaspDid) {
      return;
    }

    //add the active vasp to the datadog RUM user session
    addDatadogRumUserSessionValue(UserSessionPropertyOptions.VASP_DID, activeVaspDid);

    const { roles } = currentViewer;
    const userRole = roles?.find((role) => role.vaspDID === activeVaspDid)?.role;
    if (userRole) {
      addDatadogRumUserSessionValue(UserSessionPropertyOptions.ROLE, userRole);
    }
  }, [activeVaspDid, currentViewer]);

  useEffect(() => {
    if (activeVaspDid) {
      // every time activeVasp changes, we persist in localStorage
      localStorage.setItem("activeVasp", activeVaspDid);
      // also clear out the company creation wizard in case the user had been in the middle of creating one
      setCompanyWizardState({});
    }
  }, [activeVaspDid, setCompanyWizardState]);

  const state = {
    activeVaspDid,
    getActiveVasp: () => {
      return data?.loginable.find((_vasp) => _vasp.did === activeVaspDid);
    },
    clearActiveVasp: () => {
      /* clears the Selected vasp, so resgister-vasp can create a new one without being redirected*/
      sessionStorage.setItem("disableMultiVaspSigninRedirect", "true");
      setActiveVASPDid(VASP_CONTEXT_INITIAL_DEFAULT_VALUE.activeVaspDid);
      localStorage.setItem("activeVasp", VASP_CONTEXT_INITIAL_DEFAULT_VALUE.activeVaspDid); //clear localStorage as well
    },
    vasps: data || VASP_CONTEXT_INITIAL_DEFAULT_VALUE.vasps,
    mutate,
    setActiveVASP: async (did: string) => {
      const selectedActiveVasp =
        data?.loginable.find((_vasp) => _vasp.did === did) || data?.claimable.find((_vasp) => _vasp.did === did);

      if (selectedActiveVasp) {
        setActiveVASPDid(selectedActiveVasp.did);
        await refreshViewer();
      } else {
        const isDoingOnboardingFlow = Boolean(sessionStorage.getItem("disableMultiVaspSigninRedirect"));
        if (isDoingOnboardingFlow) {
          return;
        }

        //this should never happen, but we return to signin for safety
        router.replace("/auth/multivasp-signin");
      }
    },

    refresh: async () => {
      await mutate();
      await refreshViewer();
      const isDoingOnboardingFlow = Boolean(sessionStorage.getItem("disableMultiVaspSigninRedirect"));
      if (isDoingOnboardingFlow) {
        return;
      }

      const selectedActiveVasp = data?.loginable.find((_vasp) => _vasp.did === activeVaspDid);
      if (selectedActiveVasp?.did) {
        await setActiveVASPDid(selectedActiveVasp.did);
      }
    },
    isLoading,
  };

  return <VaspContext.Provider value={state}>{props.children}</VaspContext.Provider>;
}
