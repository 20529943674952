import _ from "lodash";
import useSWR from "swr";

import { VaspToken } from "./types";
import {
  AddUserToVaspInput,
  AuthViewerResponse,
  RemoveUserToVaspInput,
  AuthNewCustomerInput,
  AuthUserInfoUpdateInput,
  VaspUser,
  VaspCredentialsResponse,
} from "./types";

import { fetcherThroughAuthProxyForNotabeneAuth } from "~/endpoints/utils";

/** Gets the current viewer object for the app user */
export const useAuthViewer = () => {
  const authViewerUrl = `auth/viewer`;

  const { data, error, mutate } = useSWR<AuthViewerResponse>(authViewerUrl, fetcherThroughAuthProxyForNotabeneAuth, {
    refreshInterval: 0,
    onErrorRetry: (retryError, key, config, revalidate, { retryCount }) => {
      // https://swr.vercel.app/docs/error-handling
      // Only retry up to 10 times.
      if ((retryCount || 1) >= 10) return;
      setTimeout(() => revalidate({ retryCount }), (retryCount || 1) * 1000);
    },
  });

  return {
    data: !error ? data : undefined,
    error,
    mutate,
    online: !error?.message?.startsWith("NetworkError") && !error?.message?.includes("connect ECONNREFUSED"),
    isLoading: !error && !data,
    isError: error,
  };
};

export const useVaspUsersInfo = (vaspDID: string | null) => {
  /** Gets the current viewer object for the app user */
  const vaspUsersUrl = `auth/vaspUsers/${vaspDID}`;

  const shouldFetch = vaspDID ? true : false;

  const { data, error, mutate } = useSWR<VaspUser[]>(
    shouldFetch ? vaspUsersUrl : null,
    fetcherThroughAuthProxyForNotabeneAuth,
    {
      refreshInterval: 0,
    },
  );

  return {
    data,
    mutate,
    isLoading: !error && !data,
    isError: error,
  };
};

export const postAddUserToVasp = async (payload: AddUserToVaspInput) => {
  const addUserToVaspUrl = `auth/addusertovasp`;
  return await fetcherThroughAuthProxyForNotabeneAuth(addUserToVaspUrl, { payload, requestMethod: "POST" });
};

export const postRemoveUserFromVasp = async (payload: RemoveUserToVaspInput) => {
  const removeUserFromVaspUrl = `auth/removeuserfromvasp`;
  return await fetcherThroughAuthProxyForNotabeneAuth(removeUserFromVaspUrl, { payload, requestMethod: "POST" });
};

export const postAuthNewCustomer = async (payload: AuthNewCustomerInput) => {
  const authNewCustomerUrl = `auth/customer`;
  return await fetcherThroughAuthProxyForNotabeneAuth(authNewCustomerUrl, { payload, requestMethod: "POST" });
};

export const postUserInfoUpdate = async (payload: AuthUserInfoUpdateInput) => {
  const authUserInfoUpdateUrl = `auth/userinfo/update`;
  return await fetcherThroughAuthProxyForNotabeneAuth(authUserInfoUpdateUrl, { payload, requestMethod: "POST" });
};

export const useVaspCredentials = (vaspDID: string) => {
  const vaspCredentialsUrl = `auth/credentials/${vaspDID}`;

  const shouldFetch = vaspDID ? true : false;

  const { data, error, mutate } = useSWR<VaspCredentialsResponse>(
    shouldFetch ? vaspCredentialsUrl : null,
    fetcherThroughAuthProxyForNotabeneAuth,
    {
      refreshInterval: 0,
    },
  );

  return {
    data: {
      credentials: _.get(data, "clientIds", []) as VaspToken[],
      delegateToGateway: !!data?.delegatedToClientId,
    },
    mutate,
    isLoading: !error && !data,
    isError: error,
  };
};

export const postVaspCredentials = async (vaspDID: string, delegateToGateway = false, limited = false) => {
  const url = `auth/credentials/${vaspDID}`;
  return await fetcherThroughAuthProxyForNotabeneAuth(url, {
    requestMethod: "POST",
    payload: { delegateToGateway, limited },
  });
};

export const deleteVaspCredentials = async (vaspDID: string, clientId?: string) => {
  const url = `auth/credentials/${vaspDID}${clientId ? `?clientid=${clientId}` : ""}`;
  return await fetcherThroughAuthProxyForNotabeneAuth(url, { requestMethod: "DELETE" });
};

export const rotateVaspCredentials = async (vaspDID: string, clientId?: string) => {
  const url = `auth/credentials/${vaspDID}${clientId ? `?clientid=${clientId}` : ""}`;
  return await fetcherThroughAuthProxyForNotabeneAuth(url, { requestMethod: "PUT" });
};

export const nestOrUnNestJurisdiction = async (parentVasp: string, vasp: string, action: "nest" | "unnest") => {
  const url = `auth/nest/${parentVasp}/${vasp}/${action}`;
  return await fetcherThroughAuthProxyForNotabeneAuth(url, { requestMethod: "POST" });
};
