/* eslint-disable no-useless-escape */

/* eslint-disable @typescript-eslint/ban-ts-comment */

import getConfig from "next/config";
import { NextRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();

type Dict = { [key: string]: any };
export type URLOptions = { base?: string; pathname: string; query?: Dict };

export function hostFromEmail(email = "") {
  return email.replace(/^[^@]*@/, "");
}

export const UrlRegex = /(\b(https?|ftp|file):\/\/)?[\-A-Za-z0-9+&@#\/%?=~_|!:,.;]*[\-A-Za-z0-9+&@#\/%=~_|]/;

export async function fileToBase64(file?: File | null): Promise<string | null> {
  if (!file) return null;
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as any);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

function forceHttpsProtocol(link: string): string {
  return `https://${link.replace(/^https?:\/\//, "")}`.replace("www.", "");
}

export function isEmailFromWebsite(email: string, website: string): boolean {
  try {
    const emailHost = hostFromEmail(email).toLowerCase().trim();

    const websiteHost = new URL(forceHttpsProtocol(website)).hostname.toLowerCase().trim();

    // TODO: better sub-domain check
    if (emailHost.includes(websiteHost) || websiteHost.includes(emailHost)) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export function isWebsiteFromEmailDomains(website: string, emailDomains: string[]): boolean {
  try {
    const websiteHost = new URL(forceHttpsProtocol(website)).hostname.toLowerCase().trim();

    if (emailDomains.map((d) => d.toLowerCase()).includes(websiteHost)) {
      return true;
    }
    return false;
  } catch {
    return false;
  }
}

export const getApiBaseUrl = () => publicRuntimeConfig.api.url;

export const getEnvironment = () => publicRuntimeConfig.env;

export const getServiceUrl = (service: string) => publicRuntimeConfig[service].url;

export const removeQueryParam = (param: string, router: NextRouter) => {
  const { pathname, query } = router;
  const params = new URLSearchParams(query as any);
  params.delete(param);
  router.replace({ pathname, query: params.toString() }, undefined, { shallow: true });
};
