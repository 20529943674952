import { useEffect } from "react";

import Router from "next/router";

import useVaspContext from "./useVaspContext";

import { AuthViewerResponse } from "~/endpoints/auth/types";
import useCurrentViewer from "~/hooks/useCurrentViewer";

export type SessionRedirection = { redirectTo?: string };

export function getViewerField(viewer: AuthViewerResponse | undefined, field: string) {
  for (const row of viewer?.fields || []) {
    const { fieldName, values } = row;
    if (fieldName === field) {
      return values[0].value as any;
    }
  }
  return undefined;
}

/**
 * @deprecated Please use "withRequirePermissionsLoaded" instead.
 * If you are using redirect, write the logic explicitly on the Next.js page file
 */
export default function useSession({ redirectTo }: SessionRedirection = {}) {
  const { viewer, mutate: refreshViewer, isLoading: isLoadingViewer, online } = useCurrentViewer();

  useEffect(() => {
    if (isLoadingViewer) return;
    if (online && viewer.did === "noUser" && redirectTo) {
      const redirect = Router.asPath;
      Router.push(`${redirectTo}?redirect=${redirect}`);
    }
  }, [online, viewer, redirectTo, isLoadingViewer]);

  return {
    data: { viewer: isLoadingViewer || viewer.did === "noUser" || !online ? undefined : viewer },
    mutate: refreshViewer,
    isLoading: isLoadingViewer,
    online,
  };
}

/**
 * @deprecated Please use useCurrentViewerVaspDID or useCurrentViewer
 */
export function useSessionWrap(opts: SessionRedirection = {}) {
  const { data, isLoading, online } = useSession(opts);
  const vaspContext = useVaspContext();
  const vasp = vaspContext.getActiveVasp();
  return [{ vasp, viewer: isLoading || data.viewer?.did === "noUser" || !online ? undefined : data.viewer }] as const;
}
