/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useState } from "react";

export const PUBLIC_DIRECTORY_BASEPATH = `/network/search`;
export const DASHBOARD_DIRECTORY_BASEPATH = `/dashboard/companies`;

type BackButtonContextType = {
  searchParamsStr: string;
  storeSearchParams: () => void;
  ddqBack?: string;
  setDDQBack: (ddqBack: string) => void;
};

const BACKBUTTON_INITIAL_DEFAULT_VALUE: BackButtonContextType = {
  searchParamsStr: "?",
  storeSearchParams: () => {},
  setDDQBack: () => {},
};

export const BackButtonContext = createContext<BackButtonContextType>(BACKBUTTON_INITIAL_DEFAULT_VALUE);

export function BackButtonContextProvider(props: { children: React.ReactNode }) {
  const [searchParamsStr, setSearchParamsStr] = useState<string>("?");
  const [ddqBack, setDDQBack] = useState<string>();

  const storeSearchParams = () => {
    setSearchParamsStr(window.location.search || "?");
  };

  return (
    <BackButtonContext.Provider
      value={{
        searchParamsStr,
        storeSearchParams,
        ddqBack,
        setDDQBack,
      }}
    >
      {props.children}
    </BackButtonContext.Provider>
  );
}
