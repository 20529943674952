import clsx from "clsx";
import styled from "styled-components";

import * as Bulma from "~/types/bulma";

export type ContainerProps = Bulma.Lightable & Bulma.Kinded;

/**
 * @see https://bulma.io/documentation/elements/notification/
 */
export const Container = styled.div.attrs<ContainerProps>((props) => ({
  className: clsx({
    [`notification`]: true,
    [`is-light`]: props.light,
    [`is-${props.kind}`]: Boolean(props.kind),
  }),
}))<ContainerProps>``;

export const CloseButton = styled.button.attrs({ className: clsx("delete") })``;
