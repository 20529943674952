import { useUser } from "@auth0/nextjs-auth0";

const useAuth = () => {
  const ctx = useUser();
  let isNotabeneAdmin = false;

  if (ctx.user) {
    const roles = ctx.user["https://notabene.id/roles"] as string[];
    isNotabeneAdmin = roles.includes("Notabene Admin");
  }

  return {
    ...ctx,
    isNotabeneAdmin,
    logout: () => {
      localStorage.removeItem("activeVasp");
      window.location.href = "/api/auth/logout";
    },
  };
};

export default useAuth;
