/* eslint-disable prettier/prettier */
import { v4 as uuidv4 } from "uuid";

import { globalAny } from "./AnalyticsScriptLoader";

import { AuthViewerResponse, UserField } from "~/endpoints/auth/types";
import {
  PaymentSubscriptionInfoResponse,
  SubscriptionType,
} from "~/endpoints/payment/types";
import { VASP } from "~/types/vaspid";

let consentGiven = false;

export const updateUserConsent = (userConsent: boolean) => {
  initUserConsent(userConsent);
  try {
    globalAny.analytics.track("user-consent-updated", { userConsent });
  } catch (error) {
    // console.error(error)
  }
};

export const initUserConsent = (userConsent: boolean) => {
  consentGiven = userConsent;
};

type ActionProperties = { [key: string]: string | boolean };

export const trackUserAuthenticated = (
  userData: AuthViewerResponse,
  paymentSubscriptionInfo: PaymentSubscriptionInfoResponse,
  vaspInformation: VASP
) => {
  if (!consentGiven) return;
  try {
    const userProperties: ActionProperties = {};
    const { fields, did, vasps } = userData;

    fields.forEach((field: UserField) => {
      if (field.values[0].value) {
        userProperties[field.fieldName] = field.values[0].value;
      }
    });

    globalAny.analytics.identify(did, {
      ...userProperties,
      vasps,
      isPayingCustomer:
        paymentSubscriptionInfo.subscriptionType === SubscriptionType.PAYING,
      isSunrise:
        paymentSubscriptionInfo.subscriptionType === SubscriptionType.SUNRISE,
      vaspInformation,
    });

    globalAny.analytics.group(vaspInformation.did, {
      group_vasp: vaspInformation.did,
      $name: vaspInformation.name,
      isPayingCustomer:
        paymentSubscriptionInfo.subscriptionType === SubscriptionType.PAYING,
      isSunrise:
        paymentSubscriptionInfo.subscriptionType === SubscriptionType.SUNRISE,
    });
  } catch (error) {
    // console.error(error);
  }
};

export const trackAction = (action: string, props?: ActionProperties) => {
  if (!consentGiven) return;
  try {
    globalAny.analytics.track(action, props);
  } catch (error) {
    // console.error(error);
  }
};

type TrackUserOnboardingPayload = {
  source?: string;
  vaspDID?: string;
  vaspName?: string;
  userDID?: string;
  transactionId?: string;
  onboardingTrackingId?: string;
  leiNumber?: string;
  vaspnetId?: string;
  hasGleifResult?: boolean;
  hasVASPnetResult?: boolean;
};

type TrackGleifSearchOnboardingPayload = {
  leiNumber: string;
  hasGleifResult: boolean;
  legalName?: string,
  jurisdictions?: string,
  businessNumber?: string,
  legalStructure?: string,
  yearFounded?: string,
  addressLine1?: string,
  country?: string,
  city?: string,
  postCode?: string,
  state?: string,
  number?: string,
  otherLegalName?: string,
  legalForm?: string,
  entityCategory?: string,
  entityStatus?: string,
  hqStreet?: string,
  hqNumber?: string,
  hqPostcode?: string,
  hqRegion?: string,
  hqCity?: string
};

type TrackVaspnetSearchOnboardingPayload = {
  vaspName: string; 
  jurisdiction: string;
  hasVASPnetResult: boolean;
  legalName?: string;
  name?: string;
  supervisoryAuthority?: string;
  registrationLicenseId?: string;
  regulatoryStatus?: string;
  isRegulated?: string;
  vaspnetId?: string;
};

/** Returns the trackingId or creates one and persists it if not available */
const getOnboardingTrackingId = () => {
  const onboardingPayload: TrackUserOnboardingPayload | null = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );
  if (!onboardingPayload) {
    const onboardingTrackingId = uuidv4();
    localStorage.setItem(
      "onboarding-source-tracking",
      JSON.stringify({ onboardingTrackingId })
    );
    return onboardingTrackingId;
  }
  if (!onboardingPayload.onboardingTrackingId) {
    const onboardingTrackingId = uuidv4();
    localStorage.setItem(
      "onboarding-source-tracking",
      JSON.stringify({ ...onboardingPayload, onboardingTrackingId })
    );
    return onboardingTrackingId;
  }
  return onboardingPayload.onboardingTrackingId;
};

export const trackUserOnboardingGetStarted = (
  trackingPayloadFromQueryParam: TrackUserOnboardingPayload
) => {
  const onboardingTrackingId = getOnboardingTrackingId();
  const onboardingTrackingPayload = {
    ...trackingPayloadFromQueryParam,
    onboardingTrackingId,
  };
  localStorage.setItem(
    "onboarding-source-tracking",
    JSON.stringify(onboardingTrackingPayload)
  );

  trackAction("user-onboarding-get-started", {
    ...onboardingTrackingPayload,
    source: onboardingTrackingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackOnboardingAboutYourselfPageCompleted = () => {
  const onboardingTrackingId = getOnboardingTrackingId();
  const onboardingPayload: TrackUserOnboardingPayload = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );

  trackAction("user-onboarding-about-yourself-completed", {
    ...onboardingPayload,
    source: onboardingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackOnboardingRegisterVaspPageCompleted = (
  trackingPayload: TrackUserOnboardingPayload
) => {
  const onboardingPayload = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );
  const onboardingTrackingId = getOnboardingTrackingId();

  trackAction("user-onboarding-register-vasp-page-completed", {
    ...trackingPayload,
    source: onboardingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackOnboardingGleifSearchPageCompleted =  (
  trackingPayload: TrackGleifSearchOnboardingPayload
) => {
  const onboardingPayload = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );
  const onboardingTrackingId = getOnboardingTrackingId();

  trackAction("user-onboarding-gleif-search-completed", {
    ...trackingPayload,
    source: onboardingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackOnboardingVASPNetSearchPageCompleted =  (
  trackingPayload: TrackVaspnetSearchOnboardingPayload
) => {
  const onboardingPayload = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );
  const onboardingTrackingId = getOnboardingTrackingId();

  trackAction("user-onboarding-vaspnet-search-completed", {
    ...trackingPayload,
    source: onboardingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackOnboardingVaspCreated = (
  trackingPayload: TrackUserOnboardingPayload
) => {
  const onboardingPayload = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );
  const onboardingTrackingId = getOnboardingTrackingId();

  trackAction("user-onboarding-vasp-created", {
    ...trackingPayload,
    source: onboardingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackOnboardingDocumentsUploaded = (
  trackingPayload: TrackUserOnboardingPayload & { hasUpdatedDocuments: boolean }
) => {
  const onboardingPayload = JSON.parse(
    String(localStorage.getItem("onboarding-source-tracking"))
  );
  const onboardingTrackingId = getOnboardingTrackingId();

  trackAction("user-onboarding-documents-uploaded", {
    ...trackingPayload,
    source: onboardingPayload?.source || "unknown",
    onboardingTrackingId,
  });
};

export const trackDDQUISaveAction = (
  trackingPayload:   {userDID: string, vaspDID: string, vaspName: string, page: string}
) => {
  trackAction("ddq-form-saved", trackingPayload);
}; 

export const trackDDQUICompleteAction = (
  trackingPayload:   {userDID: string, vaspDID: string, vaspName: string}
) => {
  trackAction("ddq-form-completed", trackingPayload);
}; 

export const trackDDQUIViewAction = (
  trackingPayload:   {userDID: string, vaspDID: string, ownerVASPDID: string, vaspName: string, ownerVASPName: string }
) => {
  trackAction("ddq-form-viewed", trackingPayload);
};
