/**
 * @see https://react-svgr.com/playground
 */

import React from "react";

export function Verified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_118_94" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 9.06509 22.9464 6.37627 21.1968 4.29115L10.8975 20.0432L5.0625 12.7496L6.93656 11.2503L10.7019 15.9568L19.4435 2.58696C17.3979 0.967156 14.8119 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 9.06509 22.9464 6.37627 21.1968 4.29115L10.8975 20.0432L5.0625 12.7496L6.93656 11.2503L10.7019 15.9568L19.4435 2.58696C17.3979 0.967156 14.8119 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#41E029"
      />
      <path
        d="M21.1968 4.29115L21.5798 3.96975L21.1468 3.45381L20.7783 4.01752L21.1968 4.29115ZM10.8975 20.0432L10.5071 20.3555L10.9383 20.8945L11.316 20.3168L10.8975 20.0432ZM5.0625 12.7496L4.75015 12.3592L4.35972 12.6715L4.67207 13.062L5.0625 12.7496ZM6.93656 11.2503L7.32699 10.938L7.01464 10.5475L6.62421 10.8599L6.93656 11.2503ZM10.7019 15.9568L10.3114 16.2691L10.7426 16.8081L11.1203 16.2304L10.7019 15.9568ZM19.4435 2.58696L19.862 2.86058L20.1119 2.47842L19.7539 2.19497L19.4435 2.58696ZM23.5 12C23.5 18.3513 18.3513 23.5 12 23.5V24.5C18.9036 24.5 24.5 18.9036 24.5 12H23.5ZM20.8137 4.61254C22.4906 6.61098 23.5 9.18696 23.5 12H24.5C24.5 8.94322 23.4021 6.14156 21.5798 3.96975L20.8137 4.61254ZM20.7783 4.01752L10.479 19.7695L11.316 20.3168L21.6152 4.56477L20.7783 4.01752ZM11.288 19.7308L5.45293 12.4373L4.67207 13.062L10.5071 20.3555L11.288 19.7308ZM5.37485 13.14L7.24892 11.6408L6.62421 10.8599L4.75015 12.3592L5.37485 13.14ZM6.54613 11.5627L10.3114 16.2691L11.0923 15.6444L7.32699 10.938L6.54613 11.5627ZM11.1203 16.2304L19.862 2.86058L19.0251 2.31334L10.2834 15.6832L11.1203 16.2304ZM12 0.5C14.6952 0.5 17.1727 1.42658 19.1331 2.97895L19.7539 2.19497C17.6231 0.507728 14.9286 -0.5 12 -0.5V0.5ZM0.5 12C0.5 5.64873 5.64873 0.5 12 0.5V-0.5C5.09644 -0.5 -0.5 5.09644 -0.5 12H0.5ZM12 23.5C5.64873 23.5 0.5 18.3513 0.5 12H-0.5C-0.5 18.9036 5.09644 24.5 12 24.5V23.5Z"
        fill="#7FEB6E"
        mask="url(#path-1-inside-1_118_94)"
      />
    </svg>
  );
}

export function VerifiedAlternate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" {...props}>
      <path
        d="M0.5 9.91938V1C0.5 0.723858 0.723858 0.5 1 0.5H9C9.27614 0.5 9.5 0.723858 9.5 1V9.91938C9.5 10.3386 9.01503 10.5717 8.68765 10.3098L5.93704 8.10932C5.38921 7.67106 4.61079 7.67106 4.06296 8.10932L1.31235 10.3098C0.984966 10.5717 0.5 10.3386 0.5 9.91938Z"
        fill="#B8F3AE"
        stroke="#1F1F1F"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Unverified(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={13} height={13} fill="none" {...props}>
      <mask id="prefix__a" fill="#fff">
        <path d="M12 6A6 6 0 110 6a6 6 0 0112 0z" />
      </mask>
      <path d="M12 6A6 6 0 110 6a6 6 0 0112 0z" fill="#BABCBA" />
      <path
        d="M11.5 6A5.5 5.5 0 016 11.5v1A6.5 6.5 0 0012.5 6h-1zM6 11.5A5.5 5.5 0 01.5 6h-1A6.5 6.5 0 006 12.5v-1zM.5 6A5.5 5.5 0 016 .5v-1A6.5 6.5 0 00-.5 6h1zM6 .5A5.5 5.5 0 0111.5 6h1A6.5 6.5 0 006-.5v1z"
        fill="#BABCBA"
        mask="url(#prefix__a)"
      />
      <path fill="#fff" d="M8.047 3.334l.943.942-4.715 4.715-.942-.943z" />
      <path fill="#fff" d="M8.99 8.048l-.942.942-4.714-4.714.942-.943z" />
    </svg>
  );
}

export function Pending(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 18A9 9 0 109 0a9 9 0 000 18zm0-5a4 4 0 100-8 4 4 0 000 8z"
        fill="#35BAEE"
      />
    </svg>
  );
}

export function Claimed(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 16.4611 2.43435 20.3537 6.04713 22.4217V5.98447H19.6312V15.0405H8.31115V23.4223C9.47334 23.7974 10.713 24 12 24ZM8.31114 8.24849H17.3672V12.7765H8.31114V8.24849Z"
        fill="#41E029"
      />
    </svg>
  );
}

export function ClaimedAlternate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <path
        d="M11.2416 2.70203L9.28724 1.14235L4.36881 7.30536L2.41455 5.74583L0.854891 7.70026L4.76357 10.8195L11.2416 2.70203Z"
        fill="#B8F3AE"
        stroke="#1F1F1F"
      />
    </svg>
  );
}

export function SelfReported(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={18} height={18} fill="none" {...props}>
      <mask id="prefix__a" fill="#fff">
        <path d="M18 9A9 9 0 110 9a9 9 0 0118 0z" />
      </mask>
      <path d="M18 9A9 9 0 110 9a9 9 0 0118 0z" fill="#EAB76C" />
      <path
        d="M17.5 9A8.5 8.5 0 019 17.5v1A9.5 9.5 0 0018.5 9h-1zM9 17.5A8.5 8.5 0 01.5 9h-1A9.5 9.5 0 009 18.5v-1zM.5 9A8.5 8.5 0 019 .5v-1A9.5 9.5 0 00-.5 9h1zM9 .5A8.5 8.5 0 0117.5 9h1A9.5 9.5 0 009-.5v1z"
        fill="#E0AE62"
        mask="url(#prefix__a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.908 4l2.79 3.06-1.34 3.477-4.123.395L6.908 4zm2.79 3.06l2.938 1.133a1.852 1.852 0 01-1.061 3.538l-1.568 1.84a.4.4 0 01-.649-.466l1.077-1.791-2.07-.798 1.333-3.455z"
        fill="#fff"
      />
    </svg>
  );
}

export function InNetworkAlternate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
      <mask id="path-1-inside-1_0_330" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1C1.79086 1 0 2.79086 0 5C0 7.20914 1.79086 9 4 9H5V12L8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1H4Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C1.79086 1 0 2.79086 0 5C0 7.20914 1.79086 9 4 9H5V12L8 9C10.2091 9 12 7.20914 12 5C12 2.79086 10.2091 1 8 1H4Z"
        fill="#B8F3AE"
      />
      <path
        d="M5 9H6V8H5V9ZM5 12H4V14.4142L5.70711 12.7071L5 12ZM8 9V8H7.58579L7.29289 8.29289L8 9ZM1 5C1 3.34315 2.34315 2 4 2V0C1.23858 0 -1 2.23858 -1 5H1ZM4 8C2.34315 8 1 6.65685 1 5H-1C-1 7.76142 1.23858 10 4 10V8ZM5 8H4V10H5V8ZM6 12V9H4V12H6ZM7.29289 8.29289L4.29289 11.2929L5.70711 12.7071L8.70711 9.70711L7.29289 8.29289ZM11 5C11 6.65685 9.65685 8 8 8V10C10.7614 10 13 7.76142 13 5H11ZM8 2C9.65685 2 11 3.34315 11 5H13C13 2.23858 10.7614 0 8 0V2ZM4 2H8V0H4V2Z"
        fill="#1F1F1F"
        mask="url(#path-1-inside-1_0_330)"
      />
      <circle cx="3" cy="5" r="1" fill="#1F1F1F" />
      <circle cx="6" cy="5" r="1" fill="#1F1F1F" />
      <circle cx="9" cy="5" r="1" fill="#1F1F1F" />
    </svg>
  );
}
