import React from "react";

import ReactTooltip, { TooltipProps } from "react-tooltip";
import styled from "styled-components";

import * as Statuses from "~/components/icons/Statuses";

const FlexContainerWrap = styled.div`
  display: flex;
  flex-wrap: ${(props: { wrapBadges: boolean }) => (props.wrapBadges ? "wrap" : "nowrap")};
  overflow: ${(props: { wrapBadges: boolean }) => (props.wrapBadges ? "auto" : "hidden")};
  gap: 6px;
`;

const Status = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border: 1px solid #1f1f1f;
  border-radius: 70px;
  gap: 4px;
`;

const StatusText = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 800;
  color: #1f1f1f;
  white-space: ${(props: { wrapText: boolean }) => (props.wrapText ? "normal" : "nowrap")};
`;

const TooltipContentContainer = styled.div`
  position: relative;
  max-width: 215px;
  font-size: 12px;
  padding: 4px 8px;
`;

export function VaspStatusTooltipElements() {
  const toolTipProps: TooltipProps = {
    place: "bottom",
    effect: "solid",
    border: true,
    borderColor: "black",
    backgroundColor: "black",
    textColor: "white",
  };

  return (
    <>
      <style>{`
        .tooltip-reset-styles {
          padding: 0px !important;
          white-space: normal !important;
        }
      `}</style>
      <ReactTooltip className={`tooltip-reset-styles`} {...toolTipProps} id="claimed-tooltip">
        <TooltipContentContainer>
          <p>If claimed, an employee of the company has successfully claimed this account.</p>
        </TooltipContentContainer>
      </ReactTooltip>
      <ReactTooltip className={`tooltip-reset-styles`} {...toolTipProps} id="in-network-tooltip">
        <TooltipContentContainer>
          <p>This VASP is receiving Travel Rule data transfers.</p>
        </TooltipContentContainer>
      </ReactTooltip>
      <ReactTooltip className={`tooltip-reset-styles`} {...toolTipProps} id="super-vasp-tooltip">
        <TooltipContentContainer>
          <p>This company has been claimed, Notabene verified, and participates in-network.</p>
        </TooltipContentContainer>
      </ReactTooltip>
    </>
  );
}

export default styled(
  ({
    isVerified,
    isClaimed,
    isInNetwork,
    hideDeactivatedBadges = false,
    wrapBadges = true,
    forceShowBadges = false,
  }: {
    isVerified: boolean;
    isClaimed: boolean;
    isInNetwork: boolean;
    hideDeactivatedBadges?: boolean;
    wrapBadges?: boolean;
    forceShowBadges?: boolean;
  }) => {
    const isSuperVASP = isVerified && isClaimed && isInNetwork;
    const showBadges = isVerified || isClaimed || isInNetwork;

    if (!forceShowBadges && !showBadges) {
      return null;
    }

    const inactiveStyle = { opacity: "0.25", filter: "grayscale(1)" };

    if (isSuperVASP) {
      return (
        <FlexContainerWrap wrapBadges={wrapBadges}>
          <Status data-testid="super-vasp-badge" data-tip data-for="super-vasp-tooltip">
            <Statuses.VerifiedAlternate style={{ marginRight: 4 }} />
            <Statuses.ClaimedAlternate style={{ marginRight: 4 }} />
            <Statuses.InNetworkAlternate style={{ marginRight: 4 }} />
            <StatusText wrapText={wrapBadges}>Super VASP</StatusText>
          </Status>
        </FlexContainerWrap>
      );
    } else {
      return (
        <FlexContainerWrap wrapBadges={wrapBadges}>
          {(!hideDeactivatedBadges || isVerified) && (
            <Status
              data-testid="verified-badge"
              style={isVerified ? {} : inactiveStyle}
              data-tip
              data-for="verified-tooltip"
            >
              <Statuses.VerifiedAlternate style={{ marginRight: 4 }} />
              <StatusText wrapText={wrapBadges}>Verified</StatusText>
            </Status>
          )}

          {(!hideDeactivatedBadges || isClaimed) && (
            <Status
              data-testid="claimed-badge"
              style={isClaimed ? {} : inactiveStyle}
              data-tip
              data-for="claimed-tooltip"
            >
              <Statuses.ClaimedAlternate style={{ marginRight: 4 }} />
              <StatusText wrapText={wrapBadges}>Claimed</StatusText>
            </Status>
          )}

          {(!hideDeactivatedBadges || isInNetwork) && (
            <Status
              data-testid="in-network-badge"
              style={isInNetwork ? {} : inactiveStyle}
              data-tip
              data-for="in-network-tooltip"
            >
              <Statuses.InNetworkAlternate style={{ marginRight: 4 }} />
              <StatusText wrapText={wrapBadges}>In-Network</StatusText>
            </Status>
          )}
        </FlexContainerWrap>
      );
    }
  },
)`
  display: block;
`;
