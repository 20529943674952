import { PaymentSubscriptionInfoResponse, VaspSubscriptionsResponse } from "./types";

export function vaspSubscriptionsResponseToSubscriptionInfo(
  vaspSubscription: VaspSubscriptionsResponse,
): PaymentSubscriptionInfoResponse {
  // We always and only have one subscription per vasp at the moment in our db
  const subscription = vaspSubscription.data[0];

  return {
    did: subscription.did,
    customerId: subscription.customer_id,
    subscriptionId: subscription.subscription_id,
    currentBillingPeriodUsage: subscription.current_billing_period_usage,
    subscriptionType: subscription.subscription_type,
    subscriptionStatus: subscription.subscription_status,
    provider: subscription.provider,
    ...(subscription.portal_session_url && { portalSessionUrl: subscription.portal_session_url }),
    ...(subscription.invoice_info && {
      invoiceInfo: {
        invoices: subscription.invoice_info?.invoices
          ? subscription.invoice_info.invoices.map((invoice) => ({
              date: invoice.date,
              dueDate: invoice.due_date,
              status: invoice.status,
            }))
          : [],
        hostedPageUrl: subscription.invoice_info.hosted_page_url,
      },
    }),
  };
}
