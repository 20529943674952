import Script from "next/script";

const globalAny: any = global; // Make ts happy with global object
const LocalizeJSKey = "2jWbbNOOq8v8F";

export default function LocalizeJSLoader() {
  return (
    <>
      <Script
        id="localize"
        src="https://global.localizecdn.com/localize.js"
        onLoad={() => {
          !(function (a: any): any {
            if (!a.Localize) {
              a.Localize = {};
              for (
                let e = [
                    "translate",
                    "untranslate",
                    "phrase",
                    "initialize",
                    "translatePage",
                    "setLanguage",
                    "getLanguage",
                    "getSourceLanguage",
                    "detectLanguage",
                    "getAvailableLanguages",
                    "untranslatePage",
                    "bootstrap",
                    "prefetch",
                    "on",
                    "off",
                    "hideWidget",
                    "showWidget",
                  ],
                  t = 0;
                t < e.length;
                t++
              )
                a.Localize[e[t]] = function () {
                  return undefined; // make ts happy
                };
            }
          })(window);
          globalAny.Localize.initialize({
            key: LocalizeJSKey,
            rememberLanguage: true, // GDPR?
            // detecting & saving new phrases is disabled by default on all environments except QA
            saveNewPhrases: window.location.hostname === "qa.eu.notabene.dev",
          });
        }}
      />
    </>
  );
}
