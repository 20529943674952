import { useEffect, useState } from "react";

import { useIdleTimer } from "react-idle-timer";

import useAuth from "~/utils/useAuth";

const INACTIVITY_TIMEOUT_MINUTES = 15;

function IdleDetector() {
  const { user, logout, isLoading: isLoadingAuth } = useAuth();

  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const [iddleState, setIdleState] = useState<"Active" | "Idle" | null>(null);

  useEffect(() => {
    if (iddleState === "Idle" && isLoggedIn === true) {
      logout();
    }
  }, [iddleState, isLoggedIn, logout]);

  useEffect(() => {
    if (isLoadingAuth) {
      return;
    }
    setIsLoggedIn(Boolean(user));
  }, [user, isLoadingAuth, setIsLoggedIn]);

  useIdleTimer({
    onIdle: () => setIdleState("Idle"),
    onActive: () => setIdleState("Active"),
    timeout: INACTIVITY_TIMEOUT_MINUTES * 60 * 1000,
    throttle: 500,
  });

  return null;
}

export default IdleDetector;
