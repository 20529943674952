import useVaspContext from "~/hooks/useVaspContext";

/**
 * Returns the VASP DID that the currentViewer is operating
 * A viewer can belong to several VASPs
 */
export default function useCurrentViewerVaspDID() {
  const vaspContext = useVaspContext();
  return vaspContext.activeVaspDid;
}
