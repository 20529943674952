import { css, FlattenInterpolation, ThemeProps, DefaultTheme } from "styled-components";

import * as Bulma from "~/types/bulma";

export type CSSInterpolation = FlattenInterpolation<ThemeProps<DefaultTheme>>;

export const GAP = "0.75rem"; // from Bulma

export const SIZES = {
  1: "3rem",
  2: "2.5rem",
  3: "2rem",
  4: "1.5rem",
  5: "1.25rem",
  6: "1rem",
  7: "0.75rem",
};

export const THEME_BASE: DefaultTheme = {
  colors: {
    main: "var(--colors-scheme-main)",
    primary: "var(--colors-primary)",
    danger: "var(--colors-danger)",
    warning: "var(--colors-warning)",
    success: "var(--colors-success)",
    link: "var(--colors-link)",
    info: "var(--colors-info)",
    text: "var(--colors-text)",
    white: "var(--colors-white)",
    black: "var(--colors-black)",
    lightGray: "var(--colors-light-gray)",
    darkerGray: "var(--colors-darker-grey)",
    backgroundGray: "var(--colors-background-gray)",
    lightYellow: "var(--colors-yellow-light)",
  },
  shadows: {
    soft: "var(--shadows-light)",
    hard: "var(--shadows-dark)",
  },
  weights: {
    light: "var(--weight-light)",
    normal: "var(--weight-normal)",
    medium: "var(--weight-medium)",
    semibold: "var(--weight-semibold)",
    bold: "var(--weight-bold)",
    bolder: "var(--weight-bolder)",
  },
  radius: "0",
  gap: GAP,
  container: {
    offset: `calc(${GAP} * 2)`,
  },
  // https://github.com/jgthms/bulma/blob/master/sass/utilities/initial-variables.sass
  breakpoints: {
    mobile: "0px",
    tablet: "769px",
    desktop: "1024px",
    widescreen: "1216px",
    fullhd: "1408px",
  },
  googleFont:
    "https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800,900|Montserrat:300,400,500,600,700,800,900|Roboto+Slab:300,400,500,600,700,800,900",
};

export const THEME = THEME_BASE;

/**
 * Responsiveness helpers.
 * TODO: take breakpoints from Bulma SASS
 *
 * @see https://github.com/jgthms/bulma/issues/1818
 * @see https://bulma.io/documentation/overview/responsiveness/#breakpoints
 *
 * @example
 * const Text = styled.p`
 *   font-size: 1em;
 *   ${from.tablet(css`
 *     font-size: 2em;
 *   `)}
 * `;
 */
export const from = {
  mobile(interpolation: CSSInterpolation) {
    const breakpoint = THEME_BASE.breakpoints.mobile;
    return this.breakpoint(breakpoint, interpolation);
  },
  tablet(interpolation: CSSInterpolation) {
    const breakpoint = THEME_BASE.breakpoints.tablet;
    return this.breakpoint(breakpoint, interpolation);
  },
  desktop(interpolation: CSSInterpolation) {
    const breakpoint = THEME_BASE.breakpoints.desktop;
    return this.breakpoint(breakpoint, interpolation);
  },
  widescreen(interpolation: CSSInterpolation) {
    const breakpoint = THEME_BASE.breakpoints.widescreen;
    return this.breakpoint(breakpoint, interpolation);
  },
  fullhd(interpolation: CSSInterpolation) {
    const breakpoint = THEME_BASE.breakpoints.fullhd;
    return this.breakpoint(breakpoint, interpolation);
  },
  breakpoint(breakpoint: string | number, interpolation: CSSInterpolation) {
    return css`
      @media screen and (min-width: ${breakpoint}), print and (min-width: ${breakpoint}) {
        ${interpolation};
      }
    `;
  },
};

export const SAFE_AREA = {
  TOP(defaultValue?: number | string) {
    return this.GENERATE("top", defaultValue);
  },
  RIGHT(defaultValue?: number | string) {
    return this.GENERATE("right", defaultValue);
  },
  BOTTOM(defaultValue?: number | string) {
    return this.GENERATE("bottom", defaultValue);
  },
  LEFT(defaultValue?: number | string) {
    return this.GENERATE("left", defaultValue);
  },
  GENERATE(position: string, defaultValue?: number | string) {
    if (defaultValue) {
      return `env(safe-area-inset-${position}, ${defaultValue})`;
    } else {
      return `env(safe-area-inset-${position})`;
    }
  },
};

export function ALIGNMENT({ align }: Bulma.Alignment) {
  switch (align) {
    case "justified":
      return "space-between";
    case "centered":
      return "center";
    case "right":
      return "flex-end";
    case "left":
      return "flex-start";
    default:
      return undefined;
  }
}

export const textEllipsisStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  width: "95%",
  textOverflow: "ellipsis",
};

export const customStyles = {
  menu: (provided: any) => ({ ...provided, zIndex: 100, borderRadius: 4 }),

  control: (provided: any, state: { isFocused: any }) => ({
    ...provided,
    color: "white",
    background: "white",
    cursor: "pointer",
    fontSize: "14px",
    borderRadius: 4,
    border: "1px solid #BDBDBD",
    "&:hover": { borderColor: "#828282" },
    ...(state.isFocused ? { borderColor: "#1F1F1F !important", boxShadow: "none !important" } : {}),
    justifyContent: "space-between",
    alignItems: "center",
  }),
  option: (provided: any, state: { isSelected: boolean; isFocused: boolean; isDisabled: boolean }) => ({
    ...provided,
    fontSize: 14,
    fontWeight: 500,
    cursor: "pointer",
    ...(state.isFocused && {
      backgroundColor: "#F1FDEF",
      "&:active": { backgroundColor: "#F2F2F2" },
    }),
    ...(state.isSelected && {
      backgroundColor: "#F1FDEF",
      color: "#231F20",
    }),
    ...(state.isDisabled && {
      backgroundColor: "white",
      color: "#E9E9E9",
    }),
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: "#363636",
    fontSize: 12,
    fontWeight: 700,
  }),
  multiValue: (provided: any) => ({
    ...provided,
    backgroundColor: "#F2F2F2",
    borderRadius: 2,
    margin: 4,
    "&:hover": { boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.4)", transition: "0.2s" },
  }),
  multiValueLabel: (provided: any) => ({
    ...provided,
    color: "#1F1F1F",
  }),
  multiValueRemove: (provided: any) => ({
    ...provided,
    color: "#333333",
    ":hover": {
      backgroundColor: " #F2F2F2",
      color: "#333333",
    },
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    color: "#1F1F1F",
    ":hover": { color: "1F1F1F" },
  }),
  placeholder: (provided: any) => ({
    ...provided,
    ...textEllipsisStyle,
    color: "#363636",
    fontSize: 14,
  }),
};

export const sortBySelectCustomStyles = {
  ...customStyles,
  container: (provided: any) => ({
    ...provided,
    width: 172,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    fontSize: 12,
  }),
};

export const filterBySelectCustomStyles = {
  ...customStyles,
  container: (provided: any) => ({
    ...provided,
    width: 140,
  }),
  menu: (provided: any) => ({ ...provided, zIndex: 100, borderRadius: 4, width: "max-content" }),
};

export const dateRangeSelectCustomStyles = {
  ...customStyles,
  container: (provided: any) => ({
    ...provided,
    width: 200,
  }),
  placeholder: (provided: any) => ({
    ...provided,
    ...textEllipsisStyle,
    color: "#363636",
    fontWeight: 700,
    fontSize: 12,
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    color: "#363636",
    fontWeight: 700,
    fontSize: 12,
  }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 100, // Needed for the outgoing/incoming/internal arrows of the transaction row to not overlap the date range selector
    boxShadow: "0px 1px 10px 0px rgba(0, 0, 0, 0.28)",
    fontSize: 14,
    fontWeight: 500,
    minWidth: 260,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    ...(state.isFocused && {
      backgroundColor: "#F1FDEF",
      "&:active": { backgroundColor: "#F2F2F2" },
    }),
    ...(state.isSelected && {
      backgroundColor: "#F1FDEF",
      color: "#231F20",
    }),
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
};
