import clsx from "clsx";
import styled from "styled-components";

import { Text } from "~/components/Text";
import * as Bulma from "~/types/bulma";

export type ButtonsProps = Bulma.Sized & Bulma.Addons & Bulma.Alignment & Bulma.Gaping;
export const Buttons = styled.div.attrs<ButtonsProps>((props) => ({
  className: clsx({
    [`buttons`]: true,
    [`is-${props.align}`]: Boolean(props.align),
    [`are-${props.sized}`]: Boolean(props.sized),
    [`is-marginless`]: props.marginless,
    [`is-paddingless`]: props.paddingless,
    [`has-addons`]: props.addons,
  }),
}))<ButtonsProps>``;

export type ClickableProps = React.HTMLProps<HTMLButtonElement> &
  Bulma.Common &
  Bulma.Text &
  Bulma.Lightable &
  Bulma.Expandable &
  Bulma.Loadable &
  Bulma.Outlined &
  Bulma.Gaping;

export const Clickable = styled(Text).attrs<ClickableProps>((props) => ({
  className: clsx({
    [`is-${props.kind}`]: Boolean(props.kind),
    [`is-${props.sized}`]: Boolean(props.sized),
    [`is-marginless`]: props.marginless,
    [`is-paddingless`]: props.paddingless,
    [`is-fullwidth`]: props.fullwidth,
    [`is-loading`]: props.loading,
    [`is-light`]: props.light,
    [`is-outlined`]: props.outlined,
  }),
}))<ClickableProps>``;

export const Button = styled(Clickable as "button").attrs<ClickableProps>({
  className: clsx({
    [`button`]: true,
  }),
})<ClickableProps>`
  border-radius: 4px;
`;
Button.defaultProps = {
  as: "button",
  type: "button",
  weight: "bolder",
};

/**
 * Like a button but transparent. For an Anchor link please use `<Anchor href="" />
 */
export const ButtonLink = styled(Button)`
  &&& {
    text-decoration: none;
  }
`;
ButtonLink.defaultProps = {
  weight: "bolder",
  kind: "text",
  color: "grey-dark",
};

export const Anchor = styled(Clickable as "a")<ClickableProps>``;
Anchor.defaultProps = {
  as: "a",
  paddingless: true,
  marginless: true,
  weight: "bolder",
  kind: "text",
  color: "grey-dark",
};

/**
 * Destruct this props to make <a/> open in a new tab
 * @example
 * <a {...NEW_TAB} href="https//example.com" />
 */
export const NEW_TAB = {
  rel: "noopener noreferrer",
  target: "_blank",
};

/**
 * It is recommended to add `tabIndex={0}`
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/button_role
 */
export const ClickableText = styled(Clickable).attrs<ClickableProps>({
  role: "button",
})<ClickableProps>`
  cursor: pointer;
  white-space: break-spaces;

  &[disabled],
  fieldset[disabled] {
    user-select: none;
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.5;
  }
`;
