/* eslint-disable import/order */
import { ComponentType, useEffect, useRef, useState } from "react";
import { datadogRum, IGNORED_ROUTES_FOR_DD_RUM_INIT, initDatadogRum } from "../utils/dd-rum";

import { UserProvider } from "@auth0/nextjs-auth0";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import type { AppProps } from "next/app";
import { IntercomProvider } from "react-use-intercom";
import styled, { createGlobalStyle, ThemeProvider } from "styled-components";
import { SWRConfig } from "swr";
import LocalizeJSLoader from "~/components/LocalizeJS";

// eslint-disable-next-line import/order
import IntercomChat from "~/components/IntercomChat";
import NotabeneToastContainer from "~/components/NotabeneToastContainer";
import { useFlags } from "launchdarkly-react-client-sdk";

const INTERCOM_APP_ID = "jrbarfnk";

// Import and compile SCSS
import "~/styles/custom-bulma.scss";
import "~/styles/custom-date-picker.scss";

import TagManager from "react-gtm-module";
import AnalyticsScriptLoader from "~/analytics/AnalyticsScriptLoader";
import { Text } from "~/components/Text";
import { BackButtonContextProvider } from "~/contexts/BackButtonContext/BackButtonContext";
import { VaspContextProvider } from "~/contexts/VaspContext";
import { ViewerContextProvider } from "~/contexts/ViewerContext";
import { postWithoutToken } from "~/endpoints/utils";
import { THEME } from "~/styles/theme";
import { VaspStatusTooltipElements } from "~/templates/companies/components/VASPStatus";
import { useRouter } from "next/router";
import { WebhookContextProvider } from "~/contexts/WebhookContext";
import IdleDetector from "~/components/IdleDetector";

const GlobalStyle = createGlobalStyle`
  html {
    --Black-1: #1F1F1F;
    overflow-y: auto;
  },
  body {
    position: relative;
    min-height: 100vh;
    scroll-behavior: smooth;
  }
`;

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const hasDatadogRUMInitialised = useRef(false);
  const { dashboardIdleUserLogout } = useFlags();

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-PJDJ7PX" });
  }, []); // Empty array means this will execute only once

  useEffect(() => {
    if (hasDatadogRUMInitialised.current) return;
    if (IGNORED_ROUTES_FOR_DD_RUM_INIT.includes(router.asPath)) return;

    initDatadogRum();
    hasDatadogRUMInitialised.current = true;
  }, [router.asPath]);

  return (
    <UserProvider>
      {dashboardIdleUserLogout && <IdleDetector />}
      <ViewerContextProvider>
        <VaspContextProvider>
          <IntercomProvider appId={INTERCOM_APP_ID} autoBoot initializeDelay={3000}>
            <ThemeProvider theme={THEME}>
              <BackButtonContextProvider>
                <WebhookContextProvider>
                  <GlobalStyle />
                  <SWRConfig value={{ fetcher: postWithoutToken as any }}>
                    <EnvironmentLabel>
                      <AnalyticsScriptLoader />
                      <LocalizeJSLoader />
                      <IntercomChat>
                        <NotabeneToastContainer />
                        <Component {...pageProps} />
                        <VaspStatusTooltipElements />
                      </IntercomChat>
                    </EnvironmentLabel>
                  </SWRConfig>
                </WebhookContextProvider>
              </BackButtonContextProvider>
            </ThemeProvider>
          </IntercomProvider>
        </VaspContextProvider>
      </ViewerContextProvider>
    </UserProvider>
  );
}

const EnvironmentText = styled(Text)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 800;
  display: block;
  padding: 1px 1.5rem 4px;
  background-color: #72e85f;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  user-select: none;
`;
EnvironmentText.defaultProps = {
  color: "grey-darker",
  transform: "uppercase",
  weight: "bold",
  family: "secondary",
  align: "centered",
  size: 7,
};

// This will be removed once we connect user consent to the actual user.
const PrivacyPolicyFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 2px;
  background-color: white;
  z-index: 2;
  border-top: 1px solid #e8e8e8;
`;

function EnvironmentLabel({ children }: any) {
  const [env, setEnv] = useState<string | null>(null);
  const [showPrivacy, setShowPrivacy] = useState<boolean>();

  useEffect(() => {
    const host = (window.location.host || "").toLowerCase();
    const pathName = window.location.pathname;

    setShowPrivacy(!pathName.includes("network"));

    if (!host) {
      return setEnv("Unknown");
    } else if (host === "app.notabene.id" || host === "notabene.id") {
      return setEnv(null);
    } else if (host === "qa.eu.notabene.dev") {
      return setEnv("QA");
    } else if (host === "localhost:3001" || host === "0.0.0.0:3001") {
      return setEnv("Development");
    } else if (host === "demo.notabene.dev") {
      return setEnv("Demo");
    } else if (host === "app.notabene.dev") {
      return setEnv("Test");
    }
    return setEnv(null);
  }, []);

  return (
    <>
      {env && <EnvironmentText>{env} Environment</EnvironmentText>}
      {children}
      {showPrivacy && (
        <PrivacyPolicyFooter>
          <a rel="noreferrer" target="_blank" href="https://notabene.id/privacy-policy">
            privacy policy
          </a>
        </PrivacyPolicyFooter>
      )}
    </>
  );
}

export default withLDProvider({
  clientSideID: process.env.launchDarkly?.id,
  user: { key: "anonymous" },
  options: {
    inspectors: [
      {
        type: "flag-used",
        name: "dd-inspector",
        method: (key: string, detail) => {
          datadogRum.addFeatureFlagEvaluation(key, detail.value);
        },
      },
    ],
  },
})(MyApp as ComponentType);
