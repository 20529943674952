import clsx from "clsx";
import styled, { css } from "styled-components";

import { ALIGNMENT } from "~/styles/theme";
import * as Bulma from "~/types/bulma";

export type TextProps = Bulma.Text;

/**
 * @deprecated
 * If you want to use bulma text classes, just do it directly on your component.
 * Although probably you can achieve the same effect with Styled Components (recomended)
 * */
export const Text = styled.span.attrs<TextProps>((props) => ({
  className: clsx({
    [`is-${props.transform}`]: Boolean(props.transform),
    [`is-size-${props.size}`]: Boolean(props.size),
    [`is-family-${props.family}`]: Boolean(props.family),
    [`is-hidden`]: props.hidden,
    [`is-invisible`]: props.invisible,
    [`is-marginless`]: props.marginless,
    [`is-paddingless`]: props.paddingless,
    [`has-text-${props.color}`]: Boolean(props.color),
    [`has-text-${props.align}`]: Boolean(props.align),
  }),
}))<TextProps>`
  justify-content: ${ALIGNMENT};

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `}

  ${(props) =>
    props.transform === null &&
    css`
      text-transform: none;
    `}

  ${(props) =>
    props.weight &&
    css`
      &&& {
        font-weight: ${props.theme.weights[props.weight]};
      }
    `}
`;

/**
 * Props `as` is required, otherwise the tag will be <span/>
 */
export type TitleProps = TextProps & { as: string };

export const Title = styled(Text).attrs<TitleProps>({
  className: clsx("title"),
})<TitleProps>``;
Title.defaultProps = {
  weight: "bolder",
};

export const Subtitle = styled(Text).attrs<TitleProps>({
  className: clsx("subtitle"),
})<TitleProps>``;
Subtitle.defaultProps = {
  weight: "bolder",
  transform: "uppercase",
};

export type TextDerivateProps = TextProps & { as?: string };

/**
 * @deprecated
 * Please just use <p> (probably even not needed)
 * */
export const Paragraph = styled(Text as "p")<TextDerivateProps>``;
Paragraph.defaultProps = {
  as: "p",
};
export const Pre = styled(Text as "pre")<TextDerivateProps>``;
Pre.defaultProps = {
  as: "pre",
};
export const Code = styled(Text as "code")<TextDerivateProps>``;
Pre.defaultProps = {
  as: "code",
};

export const Epigraph = styled(Text)``;
Epigraph.defaultProps = {
  transform: "uppercase",
  size: 7,
  color: "grey",
  weight: "bolder",
};

// export const H1 = styled(Title).attrs({ as: "h1" });
// export const H2 = styled(Title).attrs({ as: "h2" });
// export const H3 = styled(Subtitle).attrs({ as: "h3" });
// export const H4 = styled(Subtitle).attrs({ as: "h4" });
// export const H5 = styled(Subtitle).attrs({ as: "h5" });
// export const H6 = styled(Subtitle).attrs({ as: "h6" });
// export const H7 = styled(Subtitle).attrs({ as: "h7" });

export type TextType = typeof Text;
